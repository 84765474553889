<template>
  <v-card>
    <v-card-text>
      <v-row dense class="mb-0 pb-0">
        <v-col cols="12" md="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            :return-value.sync="dates"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="$t('contacts.labels.filter')"
                :prepend-icon="icons.mdiCalendar"
                readonly
                outlined
                v-bind="attrs"
                dense
                v-on="on"
              >
                <template v-slot:append>
                  <v-hover v-slot="{ hover }">
                    <v-icon :color="hover ? 'primary' : ''" @click="resetDates()">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-hover>
                </template>
              </v-text-field>
            </template>

            <v-date-picker v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="#ffeeee" @click="menu = false">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>
              <v-btn text color="success" @click="getLabelValue()"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="12" md="1">
          <v-btn outlined block color="#1F979E" dense @click="deleteDialog()">
            <span>
              {{ $t('Delete') }}
            </span>
          </v-btn>
        </v-col> -->
        <v-spacer></v-spacer>
        <div class="text-center">
          <v-progress-circular
            v-if="isLoadingTag"
            indeterminate
            color="primary"
            class="mx-16 my-auto"
          ></v-progress-circular>
        </div>

        <v-col cols="12" md="auto">
          <v-btn outlined color="#1F979E" dense :block="$vuetify.breakpoint.mdAndDown" @click="toExport">
            <v-icon dark left>
              {{ icons.mdiFileDocument }}
            </v-icon>
            {{ $t('contacts.labels.export') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn block color="primary" dense @click="openConfig">
            <v-icon dark left>
              {{ icons.mdiCog }} </v-icon
            >{{ $t('contacts.labels.labelConfig') }}
          </v-btn>
        </v-col>
        <v-col cols="12" :md="this.$i18n.locale === 'en' ? '2' : '1'">
          <v-text-field
            v-model="searchUser"
            :label="$t('contacts.labels.searchUser')"
            single-line
            hide-details
            dense
            outlined
            style="width: 600px"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- table -->
    <v-card>
      <v-row style="margin: 0">
        <v-col cols="12" lg="3" md="3" sm="12" class="pt-2 scrollbar" :style="'max-height: 600px'">
          <div v-if="loadingTag" class="progress-label">
            <v-progress-circular indeterminate color="primary" class="m-16"></v-progress-circular>
          </div>
          <div v-if="!loadingTag">
            <v-checkbox
              v-for="(el, index) in allVariants"
              :key="index"
              v-model="selectVariant"
              class="ml-5 mb-6 mt-2"
              :value="`${el.id}-${el.variant}`"
              hide-details
            >
              <template v-slot:label>
                <v-card class="elevation-0" width="200px">
                  <v-row class="d-flex justify-space-between">
                    <v-icon class="mx-4" :color="el.color">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    <v-col class="p-0">
                      <h5 class="font-weight-bold">
                        {{ el.name }}
                      </h5>
                      <h6 class="font-weight-medium">
                        {{ el.variant }}
                      </h6>
                    </v-col>

                    <h5 class="font-weight-medium mt-2 p-0">
                      {{ el.totalCount }}
                    </h5>
                  </v-row>
                </v-card>
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" lg="9" md="9" sm="12">
          <v-data-table
            v-model="selectedTableData"
            :headers="columnsTags"
            :items="contactTags"
            :options.sync="options"
            :loading="loading"
            item-key="id"
            :server-items-length="totalData"
            show-select
            must-sort
            class="text-no-wrap"
            :footer-props="{
              'items-per-page-text': $t('rowsPerPageLabel'),
              'items-per-page-options': [5, 10, 25, 50, 75, 100],
            }"
          >
            <template #[`item.user`]="{ item }">
              <div class="d-flex align-center" style="white-space: initial; max-width: 20vh">
                <v-avatar size="40">
                  <v-img
                    :lazy-src="defaultProfPic"
                    :src="
                      Object.keys(item.expand).length
                        ? item.expand.contacts.editedAvatar || item.expand.contacts.avatar
                        : defaultProfPic
                    "
                    height="40"
                    width="40"
                  >
                  </v-img>
                </v-avatar>
                <div class="d-flex flex-column ms-3">
                  <span
                    style="text-align: left"
                    class="d-block font-weight-semibold text-truncate text--primary phone-number"
                    >{{
                      Object.keys(item.expand).length
                        ? item.expand.contacts.editedName || item.expand.contacts.name
                        : ''
                    }}</span
                  >
                  <span style="text-align: left" class="text-xs">
                    {{
                      Object.keys(item.expand).length && item.expand.contacts && item.expand.contacts.phoneNumber.length
                        ? item.expand.contacts.phoneNumber.join() || ''
                        : ''
                    }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.email`]="{ item }">
              <div style="white-space: initial; max-width: 20vh">
                <span style="text-align: left" class="text-sm">
                  <!-- <pre>{{ item }}</pre> -->
                  <!-- {{
                    Object.keys(item.expand).length && item.expand.contacts.email
                      ? item.expand.contacts.email.email.length
                        ? item.expand.contacts.email.email.join() || ''
                        : ''
                      : ''
                  }} -->
                  {{
                    item.expand &&
                    item.expand.contacts &&
                    item.expand.contacts.email &&
                    item.expand.contacts.email.email &&
                    item.expand.contacts.email.email.length
                      ? item.expand.contacts.email.email.join()
                      : ''
                  }}
                </span>
              </div>
            </template>
            <template #[`item.assignDate`]="{ item }">
              <div style="white-space: initial; max-width: 20vh">
                <span style="text-align: left" class="text-sm">
                  <!-- <pre>{{ item }}</pre> -->
                  {{ $moment(item.created).format('YYYY-MM-DD') }}
                </span>
              </div>
            </template>
            <template #[`item.message`]="{ item }">
              <div class="align-self-center d-flex truncate" :style="`color: grey; font-size: 12px;`">
                <span>
                  {{ Object.keys(item.expand).length && item.messages ? item.messages.content : '' }}
                  <!-- <pre>{{ item }}</pre> -->
                </span>
                <span class="ml-100"> </span>
              </div>
            </template>

            <template #[`item.tag`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    fab
                    v-bind="attrs"
                    :color="
                      Object.keys(item.expand).length && Object.keys(item.expand.tag).length
                        ? item.expand.tag.color
                        : ''
                    "
                    v-on="on"
                  >
                    {{ icons.mdiLabelVariant }}
                  </v-icon>
                </template>
                <span
                  >Tag Name :
                  {{
                    Object.keys(item.expand).length && Object.keys(item.expand.tag).length
                      ? item.expand.tag.name || ''
                      : ''
                  }}</span
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-if="paginationTagLength !== 0"
                  v-model="paginationTag"
                  :length="paginationTagLength"
                  :total-visible="15"
                  size="5px"
                  rounded="circle"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <confirm ref="confirm"></confirm>
      <v-snackbar v-model="errorExportValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        {{ $t('contacts.labels.pleaseSelectContactToExport') }}
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorExportValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorDeleteValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Please Select Contact To Delete
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorDeleteValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorDeleteFormValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Contact's Tag cannot be deleted because it has been registered in the response form
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorDeleteFormValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <dialogLabel ref="dialogLabel" @getLabelValue="getLabelValue"></dialogLabel>
    <dialogSetTags ref="dialogSetTags" @fetch-filterTag="getLabelValue"></dialogSetTags>
  </v-card>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import { default as clientPocketBase } from '@/helpers/pocketbase'

// import custom
import {
  mdiAutorenew,
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiCog,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiFileDocument,
  mdiInformation,
  mdiLabel,
  mdiLabelVariant,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import { TimeFormater } from '../../helpers'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import confirm from '../component/Confirm.vue'
import dialogChangeLabel from '../component/DialogChangeLabel.vue'
import dialogLabel from '../component/DialogLabel.vue'
import dialogSetTags from '../component/DialogSetTags.vue'

export default {
  name: 'Label',
  components: {
    confirm,
    dialogLabel,
    dialogChangeLabel,
    dialogSetTags,
  },
  mixins: [pouchDbMixin],
  props: ['tab'],
  data() {
    return {
      // SEARCH
      searchUser: '',

      // SETTING
      primaryColor: '#C5DBA4FF',
      menuPrimaryColor: false,
      dbSettings: null,
      dbRemoteSettings: null,
      tableColumns: [],
      columnsSetting: null,
      labelSetting: null,
      labelData: [],
      labelValue: [],

      // CONTACT
      dbContacts: null,
      dbRemoteContacts: null,
      contactListTable: [],
      activeFilter: null,
      errorExportValidationSnackbar: false,
      errorDeleteValidationSnackbar: false,
      errorDeleteFormValidationSnackbar: false,

      // TABLE
      totalData: 0,
      selectedlabel: '',
      selectedTableData: [],
      selectVariant: [],
      loading: false,
      loadingTag: false,
      selectedAction: '',
      actions: [
        { label: this.$t('delete'), value: 'delete' },

        // { label: this.$t('Broadcast'), value: 'broadcast' },
        { label: this.$t('changeLabel'), value: 'changeLabel' },
      ],
      columnsTags: [
        { text: '', value: 'action', sortable: false },
        { text: this.$t('contacts.labels.headers.user'), value: 'user', sortable: false, width: '20%' },
        { text: this.$t('contacts.labels.headers.email'), value: 'email', sortable: false, width: '30%' },

        // { text: 'Name', value: 'name' },
        { text: this.$t('contacts.labels.headers.message'), value: 'message' },
        { text: this.$t('contacts.labels.headers.assignDate'), value: 'assignDate', sortable: false },
        { text: this.$t('contacts.labels.headers.label'), value: 'tag', sortable: false },
      ],
      customDataTags: [
        {
          user: {
            name: 'John Doe',
            phoneNumber: '+6281234567890',
          },
          email: 'JohnDoe@gmail.com',
          message: 'Message',
          assignDate: '20 Nov 2022',
          tags: [
            {
              name: 'test',
              color: 'warning',
            },
          ],
        },
      ],
      filteredAllTag: [],
      contactTags: [],
      allVariants: {},
      options: {
        sortBy: ['last_message'],
        sortDesc: [true],
      },
      icons: {
        mdiCog,
        mdiLabel,
        mdiAutorenew,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiClose,
        mdiCheck,
        mdiInformation,
        mdiFileDocument,
        mdiCalendar,
        mdiLabelVariant,
        mdiTrashCanOutline,
        mdiPlus,
      },
      show5History: false,

      showChat: '',

      showNotes: false,
      notesData: [],
      dates: [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      menu: false,
      openTag: false,
      tags: true,
      paginationTag: 1,
      paginationTagLength: 0,
      isLoadingTag: false,
    }
  },
  computed: {
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    dateRangeText() {
      // console.log(new Date())
      return this.dates.join(' ~ ')
    },
    dataLabel() {
      // console.log(this.labelValue, 'DARI LABEL JUGA')
      return this.labelValue
        .map(label => {
          const rObj = {}

          let last_message = null
          if (label.contact.last_message) {
            last_message = this.$moment.unix(label.contact.last_message / 1000).format()
          } else if (label.chat && label.chat.original_message) {
            if (label.chat.original_message.messageTimestamp.low) {
              last_message = this.$moment.unix(label.chat.original_message.messageTimestamp.low).format()
            } else {
              last_message = this.$moment.unix(label.chat.original_message.messageTimestamp).format()
            }
          } else if (label.contact.lastMessage) {
            if (label.contact.lastMessage.original_message.messageTimestamp.low) {
              last_message = this.$moment.unix(label.contact.lastMessage.original_message.messageTimestamp.low).format()
            } else {
              last_message = this.$moment.unix(label.contact.lastMessage.original_message.messageTimestamp).format()
            }
          } else {
            last_message = null
          }

          rObj.id = label.id
          rObj.label = label.label
          rObj.label_name = label.labelName
          rObj.phone_number = label.contact ? label.contact.phone_number : ''
          rObj.phone_number_show = label.contact ? label.contact.phone_number_show : ''
          rObj.last_message = last_message
          rObj.name = label.contact ? label.contact.name : ''
          rObj.chat = label.chat ? label.chat.content : ''
          rObj.profile_picture = label.contact ? label.contact.avatar : ''
          rObj.roomId = label?.contact?.roomId ? label.contact.roomId : ''

          return rObj
        })
        .filter(x => (this.selectedlabel ? x.label.id === this.selectedlabel : x))
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    idlist() {
      return true

      // return this.$route.params.idlist
    },
  },
  watch: {
    options: {
      handler() {
        if (Object.keys(this.selectVariant).length) {
          this.fetchLabelContacts()
        }
      },
      deep: true,
    },
    tab(val, newVal) {
      if (val === 1) {
        this.selectVariant = []
        this.searchUser = ''

        /// ini opsi fetch ulang
        // if (Object.keys(this.selectVariant).length) {
        //   this.fetchLabelContacts()
        // }
      }
    },

    // searchUser() {
    //   if (this.timer) {
    //     clearTimeout(this.timer)
    //   }
    //   this.timer = setTimeout(() => {
    //     if (this.selectVariant && this.selectVariant.length) {
    //       this.getLabelValue()
    //     } else {
    //       this.getLabelValue()
    //     }
    //   }, 800)
    // },
    searchUser: _debounce(function (ev) {
      this.fetchLabelContacts(ev)
    }, 1000),
    selectVariant() {
      if (Object.keys(this.selectVariant).length) {
        this.fetchLabelContacts()
      } else {
        this.contactTags = []
        this.totalData = 0
      }
      this.searchUser = ''
    },
    selectedTableData() {
      if (this.selectedTableData.length === 0) {
        this.selectedAction = ''
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  async mounted() {
    this.loading = true

    this.getLabelValue({ action: 'create' })
    clientPocketBase.collection('crm_contact_tags').subscribe('*', e => this.getLabelValue(e))

    this.loading = false
  },
  methods: {
    async fetchLabelContacts(searchValue) {
      if (Object.keys(this.selectVariant).length) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        const tags = []
        const variants = []
        this.selectVariant.forEach(variant => {
          const splitter = variant.split('-', 1)
          splitter.push(variant.split('-').slice(1).join('-'))
          tags.push(splitter[0])
          variants.push(splitter[1])
        })
        let filter = ''
        for (let i = 0; i < tags.length; i++) {
          const tag = tags[i]
          const variant = variants[i]
          if (!i) {
            filter += `&& (tag = '${tag}' && variant='${variant}')`
          } else {
            filter += `|| (tag = '${tag}' && variant='${variant}')`
          }
        }
        if (searchValue) {
          filter += ` && (variant ~ '${searchValue}' || contacts.name ~ '${searchValue}' || contacts.phoneNumber ~ '${searchValue}' || messages ~ '${searchValue}' || contacts.email ~ '${searchValue}')`
        }
        try {
          const contactData = await clientPocketBase.collection('crm_contact_tags').getList(page, itemsPerPage, {
            filter: `created >= "${this.dates[0]}" && created < "${this.$moment(this.dates[1])
              .add(1, 'days')
              .format('YYYY-MM-DD')}" ${filter}`,
            expand: 'contacts,tag',
            $autoCancel: false,
          })
          this.contactTags = contactData?.items || []
          if (contactData) {
            this.totalData = contactData.totalItems
          }
        } catch (error) {
          console.log('🚀 ~ file: Tag.vue:479 ~ fetchLabelContacts ~ error:', error)
        }
      } else {
        this.contactTags = []
      }
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.searchUser) {
          this.getLabelValue()
        }
      }, 800)
    },
    async resetDates() {
      this.dates = [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      await this.getLabelValue()
    },
    changeDate(e) {
      // console.log(e, 'ini hehehe')
    },
    async getLabelValue(record) {
      // this.selectVariant = []

      // this.loading = true
      this.loadingTag = true
      this.allVariants = {}
      if (record?.action !== 'delete' && record?.action !== 'update') {
        this.allVariants = {}
      }
      this.contactTags = []
      try {
        this.$refs.menu.save(this.dates)
      } catch (error) {
        console.log(error, 'coy<')
      }

      // const { sortBy, sortDesc, itemsPerPage } = this.options

      // const getContactTags = await clientPocketBase
      //   .collection('crm_contact_tags')
      //   .getList(this.paginationTag, itemsPerPage, {
      //     expand: 'contacts, tag',
      //     $autoCancel: false,
      //     filter: `created >= "${this.dates[0]}" && created < "${this.$moment(this.dates[1])
      //       .add(1, 'days')
      //       .format('YYYY-MM-DD')}"`,
      //   })
      // this.paginationTagLength = Math.ceil(getContactTags.totalItems / itemsPerPage)
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/fetch-labels`

        // this.dates[1] = this.$moment(this.dates[1]).add(1, 'days').format('YYYY-MM-DD')
        const payload = {
          sub_id: this.user.sub_id,
          dates: this.dates,
        }
        const { data: labels } = await axios.post(endpoint, payload)
        this.allVariants = labels.data
        if (this.selectVariant.length) {
          const variants = this.selectVariant.map(variant => {
            const split = variant.split('-')

            return {
              tag: split[0],
              variant: split[1],
              originalValue: variant,
            }
          })
          const filter = variants.filter(select => {
            return this.allVariants.some(ele => ele.id === select.tag)
          })
          this.selectVariant = filter.map(ele => {
            return ele.originalValue
          })
        }
      } catch (error) {
        console.log('🚀 ~ file: Tag.vue:548 ~ getLabelValue ~ error:', error)
      }
      this.loadingTag = false

      // if (this.selectVariant) {
      //   if (this.selectVariant.length > 0) {
      //     let temp2
      //     let temp = []
      //     // if(this.selectVariant === `${element.id}-${element.variant}`)
      //     for (let i = 0; i < this.selectVariant.length; i++) {
      //       const elVariant = this.selectVariant[i]
      //       temp2 = this.contactTags.filter(elContact => `${elContact.id}-${elContact.variant}` === elVariant)
      //       temp2.forEach(el => temp.push(el))
      //       temp2 = []
      //     }
      //     this.contactTags = temp
      //     temp = []
      //   } else {
      //     if (this.searchUser) {
      //       const filtered = this.contactTags.filter(contact =>
      //         contact.tag[0].name.toLowerCase().includes(this.searchUser.toLowerCase()),
      //       )
      //       for (let index = 0; index < filtered.length; index++) {
      //         const element = filtered[index]
      //         if (!this.selectVariant[`${element.tag[0].name}-${element.variant}`]) {
      //           this.selectVariant[`${element.tag[0].name}-${element.variant}`] = {
      //             name: element.tag[0].name,
      //             total: 1,
      //             color: element.tag[0].color,
      //             variant: element.variant,
      //             id: element.id,
      //           }
      //         }
      //       }
      //       this.contactTags = filtered
      //     } else {
      //       this.contactTags = []
      //     }
      //   }
      // } else {
      //   this.contactTags = []
      // }

      // if (this.searchUser) {
      //   const filtered = this.contactTags.filter(
      //     contact =>
      //       contact.user.name.toLowerCase().includes(this.searchUser.toLowerCase()) ||
      //       contact.user.phoneNumber.includes(this.searchUser.toLowerCase()) ||
      //       contact.tag[0].name.toLowerCase().includes(this.searchUser.toLowerCase()) ||
      //       contact.variant.toLowerCase().includes(this.searchUser.toLowerCase()) ||
      //       contact.message.toLowerCase().includes(this.searchUser.toLowerCase()) ||
      //       contact.email.includes(this.searchUser.toLowerCase()),
      //   )
      //   this.contactTags = filtered
      //   // if (!filtered.length) {
      //   //   this.searchUser = ''
      //   // }
      // }
      // this.loading = false
    },
    getLabelName(id) {
      const obj = this.labelData.find(x => x.id === id)
      if (obj) return obj.label

      return ''
    },
    async openConfig() {
      // this.openTag = true
      this.isLoadingTag = true
      const getTags = await clientPocketBase.collection('crm_tags').getFullList(200, { $autoCancel: false })

      this.$refs.dialogLabel.open(getTags).then(async confirm => {})
      this.isLoadingTag = false
    },

    closeTag() {
      this.openTag = false
    },

    async loadSetting(fromServer = false) {
      if (fromServer) {
        await this.loadSettings()
      }
      const res = await this.loadSettingLabel(this.allSettings)
      this.columnsSetting = res.columnsSetting
      this.tableColumns = res.tableColumns
      this.labelData = res.labelData
      this.labelSetting = res.labelSetting

      // this.labelValue = res.labelValue
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    getProfPic(img, item) {
      if (img) return img

      return `${process.env.VUE_APP_WA_API_URL}ppic/${item.phone_number}@whatsapp.net/${item.phone_number}/a.jpg`
    },
    selectAction() {
      if (this.selectedAction === 'delete') {
        const self = this
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `${this.$t('deleteConfirmation')} ${this.selectedTableData.length} ${this.$t('Label')}?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async conf => {
            if (conf) {
              this.selectedTableData.forEach(data => {
                const idx = this.labelValue.findIndex(x => x.id === data.id)
                if (idx > -1) {
                  self.labelValue.splice(idx, 1)
                }
              })

              const res = await this.$store.dispatch('global/updateSettingsByKey', {
                sub_id: this.user.sub_id,
                token: this.user.token,
                key: 'label_value',
                value: this.labelValue,
              })
              if (res.status) {
                this.getLabelValue()
              }

              // Update filter label in Chat
              this.$store.commit('inbox/setLabelValue', this.labelValue)
              this.selectedAction = ''
            } else {
              this.selectedAction = ''
            }
          })
      } else if (this.selectedAction === 'changeLabel') {
        this.$refs.dialogChangeLabel.open(this.labelData, this.selectedTableData, this.labelValue).then(res => {
          if (res.status) {
            this.selectedTableData = []
          }
          this.selectedAction = ''
        })
      }
    },
    deleteLabel(data, index) {
      const self = this
      this.$refs.confirm
        .open(this.$t('delete'), `${this.$t('deleteConfirmation')} ${data.name} ?`, { color: 'red' }, this.$t('delete'))
        .then(async conf => {
          if (conf) {
            const idx = this.labelValue.findIndex(x => x.id === data.id)
            if (idx > -1) {
              self.labelValue.splice(idx, 1)
            }

            const res = await this.$store.dispatch('global/updateSettingsByKey', {
              sub_id: this.user.sub_id,
              token: this.user.token,
              key: 'label_value',
              value: this.labelValue,
            })
            if (res.status) {
              this.getLabelValue()
            }

            // Update filter label in Chat
            this.$store.commit('inbox/setLabelValue', this.labelValue)

            // self.updateSettings(self.labelSetting)
          } else {
            this.selectedAction = ''
          }
        })
    },
    isTagForm(listFormFilteredByContact, tagIdForm) {
      let isTagForm
      if (listFormFilteredByContact !== null) {
        for (let i = 0; i < listFormFilteredByContact.length; i++) {
          const tagForm = listFormFilteredByContact[i]
          if (tagForm.tags !== null) {
            for (let j = 0; j < tagForm.tags.length; j++) {
              if (!isTagForm) {
                isTagForm = tagForm.tags[j].id === tagIdForm
              }
            }
          }
          if (isTagForm === true) {
            return isTagForm
          }
          continue
        }
      } else {
        // return false
      }
    },
    async fetchContactForm(contactId) {
      // filter tag form by contact
      const formResponse = await clientPocketBase.collection('crm_form_response').getFullList(200, {
        filter: `contacts = '${contactId}' && type = 'Response'`,
      })

      const listFormFiltered = []
      for (let i = 0; i < formResponse.length; i++) {
        const idForm = formResponse[i].form
        const contactForm = await clientPocketBase.collection('crm_contact_form').getOne(idForm)
        listFormFiltered.push(contactForm)
      }

      return listFormFiltered
    },
    async deleteDialog() {
      if (!this.selectedTableData.length) {
        this.errorDeleteValidationSnackbar = true
      } else {
        for (let i = 0; i < this.selectedTableData.length; i++) {
          const tag = this.selectedTableData[i]
          const sourceId = tag.messages ? tag.messages.source_id : ''
          const listFormFilteredByContact = await this.fetchContactForm(tag.contacts)
          const tagIdForm = await this.isTagForm(listFormFilteredByContact, tag.tag)
          if (!tagIdForm) {
            const contact = tag.contacts
            let getRawTag = {}

            // const newTag = tag.rawData
            try {
              getRawTag = await clientPocketBase
                .collection('crm_contact_tags')
                .getFirstListItem(`contacts='${contact}' && sourceId = '${sourceId}'`, {
                  expand: 'tag',
                  $autocancel: false,
                })
            } catch (err) {}
            const newTag = getRawTag.rawData
            const selected = Object.keys(getRawTag.rawData)
            const updateContact = await clientPocketBase.collection('crm_contacts').update(
              contact,
              {
                tags: selected.filter(key => key !== tag.tag),
              },
              { $autocancel: false },
            )
            for (let i = 0; i < selected.length; i++) {
              // console.log(selected[i], '<console')
              const id = this.$Md5Hash(`${selected[i]}-${sourceId || contact}`)
              const newTagData = selected
                .filter(key => key !== tag.tag)
                .reduce((obj, key) => {
                  obj[key] = tag.rawData[key]

                  return obj
                }, {})
              if (tag.tag === selected[i]) {
                try {
                  const deleteContactTag = await clientPocketBase
                    .collection('crm_contact_tags')
                    .delete(tag.id, { $autoCancel: false })
                } catch (err) {
                  console.log(err, '<<err delete tag')
                }
              } else {
                try {
                  const updateContactTag = await clientPocketBase.collection('crm_contact_tags').update(
                    id,
                    {
                      rawData: newTagData,
                    },
                    { $autoCancel: false },
                  )
                } catch (err) {}
              }
            }
          } else {
            this.errorDeleteFormValidationSnackbar = true
          }

          // this.allVariants[`${tag.id}-${tag.variant}`].total -= 1
          // if (this.allVariants[`${tag.id}-${tag.variant}`].total === 0) {
          //   delete this.allVariants[`${tag.id}-${tag.variant}`]
          // }
        }
        this.selectedTableData = []
      }

      // this.$refs.confirm
      //   .open(
      //     this.idlist
      //       ? this.$t('delete')
      //       : this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed'
      //       ? this.$t('unarchived')
      //       : this.$t('archive'),
      //     `${
      //       this.idlist
      //         ? this.$t('deleteConfirmation')
      //         : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
      //         ? this.$t('unarchivedConfirmation')
      //         : this.$t('archivedConfirmation')
      //     } ${this.selectedTableData.length} ${this.$t('contacts.contact')}  ?`,
      //     { color: 'red' },
      //     this.idlist
      //       ? this.$t('delete')
      //       : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
      //       ? this.$t('unarchived')
      //       : this.$t('archive'),
      //   )
      //   .then(async agree => {
      //     if (agree) {
      //       self.loading = true
      //       self.selectedTableData.forEach(x => {
      //         if (this.idlist) {
      //           x._deleted = true
      //         } else if (
      //           this.selectFilter &&
      //           (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
      //         ) {
      //           x.archived = 0
      //         } else {
      //           x.archived = 1
      //         }

      //         if (x.lastMessage) {
      //           const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/archived-chat`
      //           const body = {
      //             subId: x.subId,
      //             roomId: x.roomId,
      //             last_message: x.lastMessage.original_message,
      //             remote_jid: x.lastMessage.original_message.key.remoteJid,
      //             archived: x.archived,
      //             server: 'wabailey',
      //           }

      //           this.$axios
      //             .post(endpoint, body)
      //             .then(response => {
      //               //console.log(response, ' == response api general')
      //             })
      //             .catch(error => {
      //               console.log(error)
      //             })
      //         }
      //       })

      //       for (const contact of self.selectedTableData) {
      //         await this.updateRoomCouchDb(this.user.subId, contact.instance_id, contact.roomId, {
      //           arvhiced: contact.archived,
      //         })
      //       }

      //       // await self.bulkUpdateContact(self.selectedTableData  , async success => {
      //       //   if (success) {
      //       //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
      //       //       await this.loadTable()
      //       //     })
      //       //   }
      //       // })
      //       await this.loadTable()
      //       self.loading = false
      //       this.selectedAction = 0
      //       this.selectedTableData = []
      //     } else {
      //       this.selectedAction = 0
      //     }
      //   })
      // this.getLabelValue()
    },
    fetchAllLabel() {
      this.filteredAllTag.push({
        text: this.$t('Tags'),
        value: 'tags',
        sortable: false,
      })
    },
    filterLabel(id, label) {
      this.selectedlabel = id
      this.activeFilter = label
    },
    viewChat(item) {
      this.show5History = true
      this.showChat = item
    },
    viewNotes(notes) {
      this.showNotes = true
      this.notesData = notes
    },
    gotoInbox(data) {
      this.$router.push({
        name: 'inbox',
        params: {
          phoneNumber: data.roomId,
        },
      })
    },
    toExport() {
      const formattedTags = []
      if (!this.selectedTableData.length) {
        this.errorExportValidationSnackbar = true
      } else {
        this.selectedTableData.forEach(tag => {
          tag.contactTag_id = tag.id
          tag.name = tag.expand.contacts.name
          tag.phone_number = tag.expand.contacts.phoneNumber
          tag.email = tag.email
          tag.assign_date = tag.assignDate
          tag.message = tag.message
          tag.tag_name = tag?.tag[0].name
          tag.tag_color = tag?.tag[0].color
          tag.variant = tag.variant
          formattedTags.push(tag)
        })

        this.exportTags(formattedTags)
      }
    },

    exportTags(data) {
      const columns = [
        {
          label: 'id',
          field: 'contactTag_id',
        },
        {
          label: 'Contact Name',
          field: 'name',
        },
        {
          label: 'Contact Phone Number',
          field: 'phone_number',
        },
        {
          label: 'Contact Email',
          field: 'email',
        },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Tag Name',
          field: 'tag_name',
        },
        {
          label: 'Tag Color',
          field: 'tag_color',
        },
        {
          label: 'Variant Name',
          field: 'variant',
        },
        {
          label: 'Contact Assign Date',
          field: 'assign_date',
        },
      ]

      // console.log(columns, data, 'ini hasil export vira')

      // naive encoding to csv format
      const csv = [columns.map(col => this.wrapCsvValue(col.label))]
        .concat(
          data.map(row =>
            columns
              .map(col =>
                this.wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                  col.format,
                ),
              )
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
      anchor.target = '_blank'
      anchor.download = 'Export Messages.csv'
      anchor.click()
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
  },
}
</script>
<style scoped>
.progress-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollbar {
  /* flex: 1;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  padding: 0; */
  overflow-y: scroll;
  width: 1px;
}
.card-note {
  background-color: #ffffff67;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #adadad;
}

.v-data-table-header__icon {
  opacity: 1;
}

@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .bottom-action {
    display: inherit;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */

  .vac-col-messages .vac-container-scroll {
    /* background: var(--chat-content-bg-color); */
    flex: 1;
    overflow-y: auto;
    overflow-anchor: none !important;
    margin-right: 1px;
    margin-top: 60px;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
