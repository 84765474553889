<template>
  <v-card>
    <!-- dialog config -->
    <v-dialog v-model="dialogLabel" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ `${$t('change')} ${$t('Label')}` }}
          </h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="agree"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <!-- <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="success"
                class="block-condition"
                @click="agree"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="error "
                class="block-condition"
                @click="cancel"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text> -->
        <v-divider />

        <v-card-text>
          <v-row>
            <v-radio-group v-model="radioGroup">
              <div v-for="(label, index) of labelData" :key="index">
                <v-col cols="12" md="12">
                  <div>
                    <v-radio :value="index">
                      <template v-slot:label>
                        <v-icon dark left :style="{ color: label.color }">
                          {{ icons.mdiLabel }}
                        </v-icon>
                        {{ label.label }}
                      </template>
                    </v-radio>
                  </div>
                </v-col>
              </div>
            </v-radio-group>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" class="mr-3" @click="openConfig">
            {{ `${$t('new')} Label` }}
          </v-btn>
          <v-btn color="success" class="mr-3" @click="agree">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog label -->
    <dialogLabel ref="dialogLabel"></dialogLabel>
  </v-card>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mdiLabel } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'

import dialogLabel from './DialogLabel.vue'

export default {
  components: {
    dialogLabel,
  },
  mixins: [pouchDbMixin],
  data: () => ({
    dialogLabel: false,
    resolve: null,
    reject: null,

    dbSettings: null,
    dbRemoteSettings: null,
    columnsSetting: null,
    labelSetting: null,
    labelData: [],
    labelValue: [],
    tableColumns: [],

    isContact: false,
    contacts: [],
    chats: [],
    contactChat: null,

    selectedLabel: [],

    icons: {
      mdiLabel,
    },
    radioGroup: -1,
  }),
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    async open(labelData, contacts, labelValue) {
      this.dialogLabel = true
      this.labelData = labelData
      this.contacts = contacts
      this.labelValue = labelValue
      this.radioGroup = -1

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      if (this.radioGroup > -1) {
        this.contacts.forEach(data => {
          const idx = this.labelValue.findIndex(x => x.id === data.id)
          if (idx > -1) {
            this.labelValue[idx].label = this.labelData[this.radioGroup]
          }
        })

        // const res = await this.$store.dispatch('global/updateSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'label_value',
        //   value: this.labelValue,
        // })
        // if (res.status) {
        //   }
        this.resolve({
          status: true,
          data: this.labelData[this.radioGroup],
        })
        this.dialogLabel = false
      } else {
        this.resolve({
          status: false,
          data: [],
        })
        this.dialogLabel = false
      }
    },
    cancel() {
      this.resolve({
        status: false,
        data: [],
      })
      this.dialogLabel = false
    },
    addNewLabel() {},
    openConfig() {
      this.$refs.dialogLabel.open(this.labelData).then(async confirm => {
        // const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'label',
        // })
        // if (labelsPg.status) {
        //   this.labelData = labelsPg.data.value
        // }
      })
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
