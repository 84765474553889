<template>
  <div>
    <v-card v-if="!contactInfoEnabled">
      <v-card-title class="text-h4 grey lighten-2">
        <v-row>
          <v-col>
            <span class="me-2">{{ $t('contacts.contactList') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <div
            :class="[`text-subtitle-2`, `ml-3`, `mt-0`, `mb-2`, `font-weight-bold`, `red--text`]"
            v-html="$t('contacts.placeHolder')"
          ></div>
        </v-row>
      </v-card-text>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <!-- <span>{{ $t(`contacts.${tab.title}`) }}</span> -->
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <contact-data :contact-info-enabled="contactInfoEnabled" @contact-info="openDetailContact"> </contact-data>
        </v-tab-item>
        <v-tab-item>
          <tag :tab="tab"></tag>
        </v-tab-item>
        <v-tab-item>
          <formData></formData>
        </v-tab-item>
        <v-tab-item>
          <hashTag></hashTag>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div v-if="contactInfoEnabled">
      <contact-data :contact-info-enabled="contactInfoEnabled" @contact-info="openDetailContact"> </contact-data>
    </div>
  </div>
</template>

<script>
import { mdiLabelVariant } from '@mdi/js'
import { ref } from '@vue/composition-api'
import hashTag from './HashTag.vue'
import tag from './Tag.vue'
import contactData from './contactData.vue'
import formData from './formData.vue'

export default {
  components: {
    contactData,
    formData,
    tag,
    hashTag,
  },
  data() {
    return {
      icons: {
        mdiLabelVariant,
      },
      contactInfoEnabled: false,
    }
  },
  data() {
    return {
      prevRoute: null,
    }
  },
  computed: {},
  watch: {},
  emits: ['contact-info'],
  mounted() {
    // //console.log(this.prevRoute.name, 'ini pathnya nyund')
    if (this.prevRoute.name === 'formDetail') {
      this.tab = 2
    }
  },
  methods: {
    openDetailContact() {
      this.$emit('contact-info')
      this.contactInfoEnabled = true
    },
  },
  setup() {
    const tab = ref('')
    const tabs = [{ title: 'Contacts' }, { title: 'Labels' }, { title: 'Form' }, { title: 'Campaign' }]
    const contactInfoEnabled = false

    return {
      tab,
      tabs,
      contactInfoEnabled,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style></style>
