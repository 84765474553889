<template>
  <v-dialog
    v-model="surveyOpen"
    :persistent="true"
    :width="$vuetify.breakpoint.xs ? '100%' : '60%'"
  >
    <v-card>
      <v-card-title>
        <h3
          v-if="dialogTitle === 'addForm'"
          class="font-weight-bold"
        >
          {{ $t('contacts.form.addForm') }}
        </h3>
        <h3
          v-else
          class="font-weight-bold"
        >
          {{ $t('contacts.form.editForm') }}
        </h3>
        <v-spacer></v-spacer>

        <v-row
          v-if="$vuetify.breakpoint.xs"
          class="my-2"
        >
          <v-col
            cols="6"
            class="justify-start"
          >
            <v-btn
              color="#1F979E"
              class="text-white"
              @click="closeDialog()"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            class="justify-end"
          >
            <v-btn
              v-if="this.tab === 0"
              color="#1F979E"
              class="text-white"
              @click="nextDialog()"
            >
              {{ $t('next') }}
            </v-btn>

            <v-btn
              v-if="this.tab === 1"
              color="#1F979E"
              class="text-white"
              @click="saveForm()"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>

        <div v-if="!$vuetify.breakpoint.xs">
          <v-btn
            color="#1F979E"
            class="text-white me-2"
            @click="closeDialog()"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="this.tab === 0"
            color="#1F979E"
            class="text-white"
            @click="nextDialog()"
          >
            {{ $t('next') }}
          </v-btn>

          <v-btn
            v-if="this.tab === 1"
            color="#1F979E"
            class="text-white"
            @click="saveForm()"
          >
            {{ $t('save') }}
          </v-btn>
        </div>

        <v-snackbar
          v-model="errorTitleValidationSnackbar"
          color="error"
          :top="true"
          style="z-index: 5 !important"
        >
          Please fill the survey title
          <template #action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="errorTitleValidationSnackbar = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-title>
      <v-divider />
      <v-tabs
        v-model="tab"
        show-arrows
        color="black"
        :style="'border: none !important'"
        class="mb-2 mx-auto"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          :style="'color:black'"
          :disabled="tab.isDisabled"
        >
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        touchless
      >
        <!-- Form -->
        <v-tab-item>
          <v-card-title
            v-if="isLoadingSurvey"
            class="justify-center"
            align="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
            ></v-progress-circular>
          </v-card-title>
          <v-card v-show="!isLoadingSurvey">
            <div id="surveyCreator" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card-title
            v-if="isLoadingSurvey"
            class="justify-center"
            align="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
            ></v-progress-circular>
          </v-card-title>
          <v-card class="my-2">
            <v-select
              v-model="selectedTags"
              :items="selectedTags"
              required
              :menu-props="{ top: false, offsetY: true, display: 'none' }"
              dense
              chips
              deletable-chips
              label="Add Labels"
              multiple
              outlined
              no-data-text
              readonly
              class="mx-4"
              @click="setOpenTag"
            ></v-select>

            <!-- <v-switch v-model="isSingleton" class="mx-4" label="Is Singleton" /> -->

            <v-card
              outlined
              class="mx-4"
            >
              <v-data-table
                item-key="id"
                :headers="customHeadersTable"
                :items="customDataTable"
                :hide-default-footer="true"
                class="text-no-wrap"
                item-text="title"
              >
                <template #[`item.visible`]="{ item }">
                  <v-checkbox v-model="item.showData"></v-checkbox>
                </template>
                <template #[`item.editable`]="{ item }">
                  <v-checkbox v-model="item.editable"></v-checkbox>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- <dialogSetTags ref="dialogSetTags" :isCancelledForm="isCancelledForm" @cancel-form="cancelForm"></dialogSetTags> -->
  </v-dialog>
</template>

<script>
import { mdiDotsVertical, mdiLabelVariant } from '@mdi/js'

import { default as clientPocketBase } from '@/helpers/pocketbase'
import { SurveyCreator } from 'survey-creator-knockout'
import contactMixin from '../../mixins/contactMixin'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'

// import custom
import pouchDbMixin from '../../mixins/pouchDbMixin'
import utilsMixin from '../../mixins/utilsMixin'
import confirm from './Confirm.vue'
import dialogBatchEdit from './DialogBatchEdit.vue'
import dialogSetTags from './DialogSetTags.vue'

import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'

const creatorOptions = {
  showEmbeddedSurveyTab: false,
  showTestSurveyTab: false,
  showOptions: false,
  showLogicTab: false,
  showJSONEditorTab: false,

  // questionTypes: ['text', 'dropdown'],
  pageEditMode: 'single',
  showTitlesInExpressions: false,
  allowEditExpressionsInTextEditor: false,
  showSurveyTitle: true,
  isAutoSave: true,
  showToolbox: false,
  showSidebar: false,
  isNavigationButtonsShowing: false,
}

export default {
  name: 'SurveyCreator',
  components: {
    confirm,
    dialogBatchEdit,
    dialogSetTags,
  },
  mixins: [firestoreDbMixin, pouchDbMixin, contactMixin, utilsMixin],

  props: ['selectedTags', 'selected', 'tagsList', 'isCancelledForm'],

  data() {
    return {
      errorTitleValidationSnackbar: false,
      snackBarTimeOut: 2000,
      tab: 0,
      tabs: [
        { title: 'Form', isDisabled: false },
        { title: 'Setting', isDisabled: true },
      ],
      customHeadersTable: [
        {
          text: 'Field',
          value: 'title',
        },
        {
          text: 'Visible',
          value: 'visible',
        },
        {
          text: 'Editable',
          value: 'editable',
        },
      ],
      customDataTable: [],
      tags: [],
      tagItems: [],
      tagProperties: [],
      surveyOpen: false,
      dialogAlert: false,
      resolve: null,
      reject: null,
      dialogForm: false,
      actionItems: [
        { title: 'Set Labels', value: 'tags' },
        { title: 'Edit', value: 'edit' },
        { title: 'Show Detail', value: 'detail' },
        { title: 'Delete', value: 'delete' },
      ],
      icons: {
        mdiDotsVertical,
        mdiLabelVariant,
      },
      selectedAction: '',
      questionSurvey: [],
      idlist: '',
      surveyCreator: '',
      defaultJson: {},
      isLoadingSurvey: false,
      dialogTitle: 'addForm',
      isSingleton: true,
      selectedFormId: '',
      tagNames: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.saveField()
      }
    },
    surveyOpen() {
      if (!this.surveyOpen) {
        this.closeDialog()
      }
    },

    // selectedTags(){
    //   this.tagNames = this.selectedTags
    //   }
    tags() {
      // console.log(this.tags, 'tags nyund')
    },
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  methods: {
    nextDialog() {
      const parsedText = JSON.parse(this.surveyCreator.text)
      if (parsedText.title) {
        this.tabs[1].isDisabled = false
        this.tab = 1
      } else {
        this.errorTitleValidationSnackbar = true
      }
    },
    open(formId) {
      const parents = this
      parents.surveyCreator = ''

      // parents.tags = []
      parents.customDataTable = []

      // parents.tagsList = []
      parents.surveyOpen = true
      parents.isLoadingSurvey = true

      this.selectedFormId = formId
      setTimeout(() => {
        parents.loadDataColumn(formId).then(() => (parents.isLoadingSurvey = false))
      })
      if (formId) {
        this.dialogTitle = 'edit'
        this.tabs[1].isDisabled = false
      } else {
        this.dialogTitle = 'addForm'
      }

      return new Promise((resolve, reject) => {
        parents.resolve = resolve
        parents.reject = reject
      })
    },
    setOpenTag() {
      if (this.selectedFormId) {
        this.dialogTitle = 'form'
        this.$emit('open-tags', this.dialogTitle, this.selectedFormId)
      } else {
        this.dialogTitle = 'addForm'
        this.$emit('open-tags', this.dialogTitle)
      }
    },
    async closeDialog() {
      this.tab = 0
      this.tabs[1].isDisabled = true
      this.tags = []
      this.$emit('clear-selectedTags')
      if (this.surveyCreator.formId && this.dialogTitle === 'addForm') {
        await clientPocketBase.collection('crm_contact_form').delete(this.surveyCreator.formId)
        this.surveyCreator = ''
      }
      this.$emit('cancel-form')
      this.surveyOpen = false
    },

    // surveyAddFormOpen() {
    //   this.surveyOpen = true
    // },
    remove(id) {
      const filtered = this.tags.filter(el => el !== id)
      this.tags = filtered
    },
    async loadDataColumn(formId) {
      this.surveyCreator = new SurveyCreator(creatorOptions)

      if (formId) {
        const foundData = await clientPocketBase.collection('crm_contact_form').getOne(formId)
        const parsedText = JSON.parse(this.surveyCreator.text)
        parsedText.elements = foundData.survey_json
        parsedText.title = foundData.name
        this.tags = foundData.tags
        this.isSingleton = foundData.is_singleton

        // this.tagNames = foundData.tags.map(el => el.name)
        // //console.log(this.tagNames, '<<<vira tag name test')
        this.customDataTable = foundData.survey_json
        this.surveyCreator.text = JSON.stringify(parsedText)

        // console.log('sayang data edit', JSON.stringify(parsedText))
        this.surveyCreator.formId = formId
      } else {
        // console.log('sayang masuk dong')
        this.defaultJson = {
          logoPosition: 'right',
          elements: [
            {
              description: 'Nama Customer saat pengisian survey',
              editable: true,
              isRequired: true,
              name: 'name',
              placeholder: 'Isi name disini',
              showData: true,
              title: 'Name',
              type: 'text',
            },
            {
              description: 'Phone Number saat pengisian survey',
              editable: true,
              isRequired: true,
              name: 'phoneNumber',
              placeholder: 'Isi Phone Number disini',
              showData: true,
              title: 'Phone Number',
              type: 'text',
            },
            {
              choicesByUrl: {
                path: 'data',
                titleName: 'text',
                url: `${process.env.VUE_APP_CMS_URL}cms/campaign-project/get-clusters-survey/${this.user.sub_id}`,
                valueName: 'value',
              },
              description: 'Cluster yang akan disurvey',
              editable: true,
              isRequired: true,
              name: 'projectCluster',
              requiredErrorText: 'Proyek yang dipilih tidak boleh kosong!',
              showData: true,
              title: 'Project - Cluster',
              type: 'dropdown',
            },
            {
              description: 'Tanggal dilakukannya survey',
              editable: true,
              inputType: 'datetime-local',
              isRequired: true,
              min: '2023-2-28T00:00',
              name: 'tanggalSurvey',
              requiredErrorText: 'Tanggal survey tidak boleh kosong!',
              showData: true,
              title: 'Tanggal Survey',
              type: 'text',
            },
            {
              description: 'Keterangan saat pengisian survey',
              editable: true,
              isRequired: true,
              name: 'keterangan',
              placeholder: 'Isi keterangan disini',
              showData: true,
              title: 'Keterangan',
              type: 'text',
            },
            {
              choicesByUrl: {
                path: 'data',
                titleName: 'text',
                url: `${process.env.VUE_APP_CMS_URL}cms/campaign-project/get-customer-acquisition/${this.user.sub_id}`,
                valueName: 'value',
              },
              description: 'CA janji survey',
              editable: true,
              isRequired: true,
              name: 'ca',
              requiredErrorText: 'CA yang dipilih tidak boleh kosong!',
              showData: true,
              title: 'CA',
              type: 'dropdown',
            },
          ],
        }
        this.surveyCreator.text = JSON.stringify(this.defaultJson)
        console.log(
          `🥶🥶🥶🥶 ~ フ ク ロ ウ file: DialogAddForm.vue:387 ~ フ ク ロ ウ loadDataColumn ~ フ ク ロ ウ defaultJson:`,
          this.defaultJson,
        )
        this.surveyCreator.formId = undefined
      }
      this.surveyCreator.toolbarItems.splice(2, 4)
      this.surveyCreator.render('surveyCreator')
    },
    async saveForm() {
      const madeSurvey = JSON.parse(this.surveyCreator.text)
      const nanoId = this.$nanoid()

      if (this.surveyCreator.formId) {
        try {
          // console.log('sayang masuk edit')
          const editForm = await clientPocketBase.collection('crm_contact_form').update(this.surveyCreator.formId, {
            name: madeSurvey.title,
            survey_json: this.customDataTable,
            subId: this.user.sub_id,
            form_id: nanoId,
            is_singleton: this.isSingleton,
            tags: this.tagsList,
            raw_data: this.selected,
          })
          this.$emit('clear-tagsList')
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      } else {
        try {
          const createdData = await clientPocketBase.collection('crm_contact_form').create({
            id: this.$Md5Hash(`${nanoId}-${this.user.sub_id}`),
            name: madeSurvey.title,
            survey_json: this.customDataTable,
            subId: this.user.sub_id,
            form_id: nanoId,
            tags: this.tagsList,
            is_singleton: this.isSingleton,
            raw_data: this.selected,
          })
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      }
      this.$emit('fetch-form')
      this.tabs[1].isDisabled = true
      this.tab = 0
      this.tags = []
      this.surveyCreator = ''
      this.surveyOpen = false
    },
    async saveField() {
      const madeSurvey = JSON.parse(this.surveyCreator.text)

      // console.log('sayang madeSurvey', madeSurvey)

      const nanoId = this.$nanoid()
      this.customDataTable = madeSurvey.elements
      this.customDataTable?.forEach(el => {
        if (!el.showData) {
          el.showData = true
        }
        if (!el.editable) {
          el.editable = true
        }
        if (!el.title) {
          el.title = el.name
        }

        return el
      })

      if (!this.surveyCreator.formId) {
        try {
          const createdData = await clientPocketBase.collection('crm_contact_form').create({
            id: this.$Md5Hash(`${nanoId}-${this.user.sub_id}`),
            name: madeSurvey.title,
            survey_json: this.customDataTable,
            subId: this.user.sub_id,
            form_id: nanoId,
            is_singleton: this.isSingleton,
            tags: this.tagsList,
            raw_data: this.selected,
          })
          this.customDataTable = createdData.survey_json
          this.surveyCreator.formId = createdData.id
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      }
    },
  },
}
</script>

<style scoped>
#surveyCreator {
  height: 100vh;
}
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-button {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
      width: 200px;
    } */
}
</style>
