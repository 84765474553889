<template>
  <v-dialog width="60%" v-model="openDialog">
    <v-card>
      <v-card-actions>
        <h3 class="font-weight-bold">
          {{ $t('Add Response') }}
        </h3>
      </v-card-actions>
      <v-divider />
      <v-card-text>
        <v-select
          v-model="contacts"
          :items="contactsData"
          item-text="name"
          item-value="id"
          label="Choose contact"
          multiple
          chips
          dense
          outlined
          append-icon=""
          class="mx-4"
          clearable
          required
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              @click:close="remove(data.item.id)"
              clearable
              close
              small
              class="py-3 px-2 my-2 mx-1"
            >
              <strong style="font-size: 12px">{{ data.item.name }}</strong>
            </v-chip>
          </template>
        </v-select>
      </v-card-text>
      <v-divider />
      <Survey :survey="surveyResponse" />
      <v-divider />
      <v-card-actions class="mt-2 mb-4 mx-1">
        <v-spacer></v-spacer>
        <v-btn color="#1F979E" class="text-white" @click="saveResponse()">
          {{ $t('save') }}
        </v-btn>
        <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
          Contact <strong v-for="contact in this.validatedContacts" key="contact">{{ contact }}</strong> already filled
          this response
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { Model, StylesManager } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-vue-ui'

StylesManager.applyTheme('defaultV2')

export default {
  name: 'ResponseSelect',
  components: {
    Survey,
  },
  props: ['formData'],
  data() {
    return {
      validatedContacts: [],
      errorTitleValidationSnackbar: false,
      openDialog: false,
      surveyResponse: '',
      contactsData: [],
      surveyJson: {
        elements: [
          {
            editable: true,
            name: 'question1',
            showData: true,
            title: 'ddwfw',
            type: 'text',
          },
        ],
      },
      contacts: [],
      title: 'add',
      formId: '',
      response: [],
    }
  },
  async mounted() {
    this.loadColumn()
  },
  beforeCreate() {},
  beforeDestroy() {
    this.surveyResponse = null
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  methods: {
    open(response) {
      this.contacts = []
      const parents = this
      // parents.fetchSurvey()
      this.response = response
      //   this.loadColumn()
      this.fetchContacts()
      this.openDialog = true
    },
    async loadColumn() {
      // //console.log("Masuk sini sayang")
      //   this.surveyJson.elements = this.response.survey_json
      this.surveyResponse = new Model(this.surveyJson)
      this.surveyResponse.showNavigationButtons = false
      //   location.reload()
    },
    async saveResponse() {
      let foundResponse = []
      this.contacts.forEach(contactId => {
        let matchContact = this.responseData.filter(response => response.id === contactId)
        if (matchContact.length) {
          matchContact.forEach(contact => {
            foundResponse.push(contact.user.name)
          })
        }
      })

      if (foundResponse.length) {
        this.errorTitleValidationSnackbar = true
        this.validatedContacts = foundResponse.map((response, index) => {
          if (index === foundResponse.length - 1) {
            return `${response} `
          }
          return `${response}, `
        })
      } else {
        const createResponses = this.contacts.map(contact => {
          const nanoId = this.$nanoid()
          return clientPocketBase.collection.create(
            'crm_form_response',
            {
              id: this.$Md5Hash(nanoId + '-' + this.user.sub_id),
              form: this.$route.params.formId,
              response_data: this.surveyResponse.data,
              subId: this.user.sub_id,
              contacts: contact,
              type: 'Response',
            },
            {
              $autoCancel: false,
            },
          )
        })

        await Promise.all(createResponses)
        this.openDialog = false
        this.$emit('fetch-response')
      }
    },
    alertResults(sender) {
      const results = JSON.stringify(sender.data)
      alert(results)
    },
    async fetchContacts() {
      try {
        let foundData = await clientPocketBase.collection('crm_form_response').getFullList(200)
        this.contactsData = foundData
      } catch (error) {
        console.log(error)
      }
    },
    remove(id) {
      let filtered = this.contacts.filter(el => el !== id)
      this.contacts = filtered
    },
  },
}
</script>
